import $ from 'jquery';

class Jobs {
	constructor(options) {
		if (options === null) {
			options = {};
		}

		this.win = $(window);
		this.options = options;
		this.onResize = this.onResize.bind(this);
		this.initialize();
	}

	setup() {
		this.$counter = 0;
		this.$holder = $('.js--m-jobs');
		this.$holderWidth = this.$holder.width();

		this.$jobsContainer = this.$holder.find('.m-jobs__container');
		this.$jobsList = this.$holder.find('.m-jobs__list');
		this.$jobItem = this.$jobsList.find('.m-jobs__item');

		this.$jobItemWidth = this.$jobItem.outerWidth(true);
		this.$jobItemHeight = this.$jobItem.outerHeight(true);
		this.$totalItems = this.$jobItem.length;

		this.$controls = this.$holder.find('.m-jobs__controls');
		this.$prevButton = this.$controls.find('.m-jobs__prev');
		this.$nextButton = this.$controls.find('.m-jobs__next');
	}

	eventListeners() {
		this.$prevButton.bind('click', () => {
			this.goLeft();
		});

		this.$nextButton.bind('click', () => {
			this.goRight();
		});

		this.win[0].addEventListener('resize', this.onResize);
	}

	onResize() {
		this.setup();
		this.setPropperties();
	}

	goLeft() {
		this.$jobsList.animate(
			{
				left: +this.$jobItemWidth
			},
			200,
			function() {
				$('.m-jobs__list .m-jobs__item:last-child').prependTo(
					'.m-jobs__list'
				);
				$('.m-jobs__list').css('left', '');
			}
		);
	}

	goRight() {
		this.$jobsList.animate(
			{
				left: -this.$jobItemWidth
			},
			200,
			function() {
				$('.m-jobs__list .m-jobs__item:first-child').appendTo(
					'.m-jobs__list'
				);
				$('.m-jobs__list').css('left', '');
			}
		);
	}

	setPropperties() {
		if (
			this.$jobItemWidth * this.$totalItems <=
			this.$jobsContainer.width()
		) {
			this.$jobsList.css({
				width: this.$jobItemWidth * this.$totalItems,
				marginLeft: 0
			});
			this.$prevButton.hide();
			this.$nextButton.hide();
		} else {
			this.$jobsList.css({
				width: this.$jobItemWidth * this.$totalItems,
				marginLeft: -this.$jobItemWidth
			});
			this.$prevButton.show();
			this.$nextButton.show();
		}
		setTimeout(function(){
			$('.m-jobs__container').css({ height: $('.m-jobs__list').height() });
		}, 200);
	}

	initialize() {
		this.setup();
		this.setPropperties();
		this.eventListeners();
	}
}

new Jobs();
