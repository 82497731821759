import $ from 'jquery';

class Campaign {
	constructor(options) {
		if (options === null) {
			options = {};
		}

		this.options = options;
		this.initialize();
	}

	setup() {
		this.$holder = $('.m-campagne');
		this.$campaignBtn = $('#m-campaign__btn');
		this.$campaignTeamBtn = $('#m-campaign__btn-team');

		let _this = this;

		this.$campaignBtn.click((e) => {
			e.preventDefault();
			_this.scrollDown();
		});

		this.$campaignTeamBtn.click((e) => {
			e.preventDefault();
			_this.scrollDownTeam();
		});
	}

	scrollDown() {
		$([document.documentElement, document.body]).animate(
			{
				scrollTop: $('#m-campaign__c2a').offset().top - 60
			},
			1000
		);
	}

	scrollDownTeam() {
		$([document.documentElement, document.body]).animate(
			{
				scrollTop: $('#m-campaign__team').offset().top - 80
			},
			1000
		);
	}

	initialize() {
		this.setup();
	}
}

new Campaign();
