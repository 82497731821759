import $ from 'jquery';
export default class JobsApply {
	constructor(holder) {
		this.$holder = $(holder);
		this.$layoutWrapper = $('body').find('#l-wrapper');

		// Binding THIS
		this.setup = this.setup.bind(this);

		// If holder exists
		if (this.$holder) {
			this.init();
		}
	}

	setup() {
		let _self = this;
		$(window).scroll(function() {
			if ($(window).width() >= 576 && $(window).width() < 992) {
				if ($(this).scrollTop() >= 340) {
					_self.$holder.addClass('is--fixed');
					_self.$layoutWrapper.addClass('is--fixed--jobs-apply');
				} else {
					_self.$holder.removeClass('is--fixed');
					_self.$layoutWrapper.removeClass('is--fixed--jobs-apply');
				}
			} else if ($(window).width() > 992 && $(window).width() < 1200) {
				if ($(this).scrollTop() >= 320) {
					_self.$holder.addClass('is--fixed');
					_self.$layoutWrapper.addClass('is--fixed--jobs-apply');
				} else {
					_self.$holder.removeClass('is--fixed');
					_self.$layoutWrapper.removeClass('is--fixed--jobs-apply');
				}
			} else if ($(window).width() > 1200) {
				if ($(this).scrollTop() >= 320) {
					_self.$holder.addClass('is--fixed');
					_self.$layoutWrapper.addClass('is--fixed--jobs-apply');
				} else {
					_self.$holder.removeClass('is--fixed');
					_self.$layoutWrapper.removeClass('is--fixed--jobs-apply');
				}
			} else {
				if ($(this).scrollTop() >= 270) {
					_self.$holder.addClass('is--fixed');
					_self.$layoutWrapper.addClass('is--fixed--jobs-apply');
				} else {
					_self.$holder.removeClass('is--fixed');
					_self.$layoutWrapper.removeClass('is--fixed--jobs-apply');
				}
			}
		});
	}

	// Initialize
	init() {
		this.setup();
	}
}
