import $ from 'jquery';

class CookieBanner {
	constructor(options) {
		if (options === null) {
			options = {};
		}

		this.options = options;
		this.initialize();
	}

	setup() {
		this.$holder = $('#js--m-cookie-banner');
	}

	getCookie(name) {
		var nameEQ = name + '=';
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1, c.length);
			}
			if (c.indexOf(nameEQ) == 0) {
				return c.substring(nameEQ.length, c.length);
			}
		}
		return null;
	}

	setCookie(name, value, days) {
		var expires = '';
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
			expires = '; expires=' + date.toUTCString();
		}
		document.cookie = name + '=' + (value || '') + expires + '; path=/';
	}

	eraseCookie(name) {
		document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	}

	removeBanner() {
		$('.m-cookie-banner__button--accept').click(() => {
			this.setCookie('limbourg_store_useractions', 'true', 365);
			$('.m-cookie-banner').addClass('m-cookie-banner--remove');
		});
	}

	declineBanner() {
		$('.m-cookie-banner__button--decline').click(() => {
			this.setCookie('limbourg_store_useractions', 'false', 365);
			$('.m-cookie-banner').addClass('m-cookie-banner--remove');
		});
	}

	checkCookie() {
		if (this.getCookie('limbourg_store_useractions')) {
			if (this.getCookie('limbourg_store_useractions') == 'false') {
				// remove user data storers
				// Google analytics
				this.removeGA();
			}
			$('.m-cookie-banner').addClass('m-cookie-banner--remove');
		} else {
			$('.m-cookie-banner').addClass('m-cookie-banner--show');
		}
	}

	removeGA() {
		$('script[type="gatc"]').remove();
	}

	initialize() {
		this.setup();
		this.removeBanner();
		this.checkCookie();
		this.declineBanner();
	}
}

new CookieBanner();
