import $ from 'jquery';

class Multilanguage {
	constructor() {
		this.initialize();
	}

	setup() {
		this.$dropdownIsVisibile = false;
		this.$holder = $('.js--m-multilanguage--mobile');
		this.$toggle = this.$holder.find('.current-menu-parent');
		this.$dropdown = this.$holder.find('.sub-menu');
	}

	addClickEvent() {
		this.$toggle.click((e) => {
			e.stopPropagation();
			this.toggleNav();
		});
	}

	toggleNav() {
		if (this.$dropdownIsVisibile) {
			this.$toggle.removeClass('is--active');
			this.$dropdown.slideUp(300);
			this.$dropdownIsVisibile = false;
		} else {
			this.$toggle.addClass('is--active');
			this.$dropdown.slideDown(300);
			this.$dropdownIsVisibile = true;
		}
	}

	initialize() {
		this.setup();
		this.addClickEvent();
	}
}

new Multilanguage();
