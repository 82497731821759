/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import $ from 'jquery';
import './index.scss';

import { Navigation, Jobs, JobsApply, Campaign } from './components/modules/index';
import { Share } from './components/objects/index';

const mJobsApply = new JobsApply('.js--m-jobs-apply');

/**
 * --------------------------------------------------------------------------
 * jQuery Detection
 * --------------------------------------------------------------------------
 */

($) => {
	if (typeof $ === 'undefined') {
		throw new TypeError(
			'The frontend requires jQuery. Jquery must be included before the framework javascript.'
		);
	}
};

/**
 * --------------------------------------------------------------------------
 * Resize Listeners
 * --------------------------------------------------------------------------
 */

window.onresize = function (event) {
	mJobsApply.setup();
};

/**
 * --------------------------------------------------------------------------
 * Scroll Listeners
 * --------------------------------------------------------------------------
 */

const isScrolling = (event) => { };

window.addEventListener('scroll', isScrolling);
