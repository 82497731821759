import $ from 'jquery';

class Share {
	constructor() {
		this.initialize();
	}

	setup() {
		this.$dropdownIsVisibile = false;
		this.$holder = $('.js--o-share');
		this.$toggle = this.$holder.find('.o-share__link');
		this.$dropdown = this.$holder.find('.o-share__menu');
	}

	addClickEvent() {
		this.$toggle.click((e) => {
			e.stopPropagation();
			this.toggleDropdown();
		});
	}

	toggleDropdown() {
		if (this.$dropdownIsVisibile) {
			this.$holder.removeClass('is--active');
			this.$dropdown.slideUp(300);
			this.$dropdownIsVisibile = false;
		} else {
			this.$holder.addClass('is--active');
			this.$dropdown.slideDown(300);
			this.$dropdownIsVisibile = true;
		}
	}

	initialize() {
		this.setup();
		this.addClickEvent();
	}
}

new Share();
