import $ from 'jquery';

class Navigation {
	constructor() {
		this.initialize();
	}

	setup() {
		this.$holder = $('.js--m-navigation');
		this.$body = $('body');
		this.$navButton = this.$body.find('.o-hamburger');
		this.$nav = this.$holder;
		this.$navList = this.$nav.find('.menu');
		this.$navItem = this.$navList.find('.m-navigation__item');
		this.$mobileNavIsVisibile = false;
	}

	eventListeneres() {
		this.$navButton.click((e) => {
			e.preventDefault();
			this.toggleNav();
		});

		this.$navItem.on('mouseenter', (e) => {
			e.stopImmediatePropagation();
			const related = e.currentTarget;
			const navItemSubmenu = $(related).find('.sub-menu');

			if ($(navItemSubmenu).length) {
				const item = $(related).children('a');
				const offset = $(item).position();

				$(navItemSubmenu)
					.find('.sub-menu__border')
					.css('left', offset.left + 20);
				$(navItemSubmenu).css('padding-left', offset.left);
			}
		});

		this.$navItem.on('mouseleave', () => {
			$('.sub-menu__border').css('left', 0);
			$('.sub-menu').css('padding-left', 0);
		});
	}

	toggleNav() {
		if (this.$mobileNavIsVisibile) {
			this.$nav.slideUp('slow');
			this.$navButton.removeClass('is--active');
			this.$body.removeClass('overflow-hidden-mobile');
			this.$mobileNavIsVisibile = false;
		} else {
			this.$nav.slideDown('slow');
			this.$navButton.addClass('is--active');
			this.$body.addClass('overflow-hidden-mobile');
			this.$mobileNavIsVisibile = true;
		}
	}

	initialize() {
		this.setup();
		this.eventListeneres();
	}
}

new Navigation();
