
import $ from 'jquery';
import slick from 'slick-carousel';  // eslint-disable-line no-unused-vars

export default class LogoCarousel {
	constructor(holder) {
		this.$holder = document.querySelector(holder);

		// Binding THIS
		this.setup = this.setup.bind(this);

		// If holder exists
		if (this.$holder) {
			this.init();
		}
	}

	setup() {

		$('.m-logo-carousel__container').slick({
			dots: true,
			infinite: true,
			nextArrow: '<div class="m-logo-carousel__next"><i class="fa fa-chevron-right"></i></div>',
			prevArrow: '<div class="m-logo-carousel__prev"><i class="fa fa-chevron-left"></i></div>',
			appendDots: $('.m-logo-carousel__dots'),
			autoplay: true,
			autoplaySpeed: 6000,
			slidesToShow: 6,
			slidesToScroll: 6,
			pauseOnHover: true,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 6,
						slidesToScroll: 6
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 5,
						slidesToScroll: 5
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 300,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});



		$(window).resize(function () {
			$('.m-logo-carousel__container').not('.slick-initialized').slick('resize');
		});

		$(window).on('orientationchange', function () {
			$('.m-logo-carousel__container').not('.slick-initialized').slick('resize');
		});
	}



	// Initialize
	init() {
		this.setup();
		//this.eventListeners();
	}
}

// New Instance of TemplateName scoped on class ".js--template-name"
new LogoCarousel('.m-logo-carousel');
